export const AboutUs = () => {
    return (
        <>
            <div className="row p-3 pt-5 p-lg-5 col-lg-11 mx-auto bg_about" active="AboutUs" id="AboutUs" >

                <div className="col-12 text-center pt-5 pb-4 px-5 mt-5 ms-lg-1" style={{backgroundColor:'#dce0fb8c'}}>
                    <h6 className="green_color FW-BOLD">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="Chocolaty_color"> WELCOME TO </span>ROYAL FERTILIZERS & CHEMICALS</h3>
                    <p className="pt-3 text-center" >
                        At Royal Fertilizers & Chemicals, we're dedicated to empowering Indian farmers with cutting-edge agricultural solutions. Since 2024, our mission has been to boost crop yields, promote sustainable farming practices, and contribute to India's agricultural growth.
                    </p>
                </div>

                <div className="col-12 col-lg-6 pt-3 pt-lg-5 pe-lg-5 pb-lg-4">
                    <h6 className="green_color FW-BOLD pt-lg-5 ">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="Chocolaty_color"> FARMER  </span> FOCUS </h3>
                    <h6 className="py-3">We're committed to guiding farmers toward prosperity:</h6>
                    {/* <p className="pt-3 pe-lg-5" style={{ textAlign: 'justify' }}>
                        Backed by our parent company, Royal Pesticides India Pvt. Ltd., we leverage expertise and innovation to deliver top-notch Fertilizerss and pesticides. Our products are carefully sourced from trusted partners, ensuring unparalleled quality and efficacy.
                    </p> */}
                    <p className=""><i class="fa-brands fa-envira pe-2" style={{color:'#03944d'}}></i> Expert crop management advice</p>
                    <p className=""><i class="fa-brands fa-envira pe-2" style={{color:'#03944d'}}></i> Training on safe product handling</p>
                    <p className=""><i class="fa-brands fa-envira pe-2" style={{color:'#03944d'}}></i> Market insights and trend analysis</p>
                    <p className=""><i class="fa-brands fa-envira pe-2" style={{color:'#03944d'}}></i> Tailored solutions for diverse agricultural needs</p>
                </div>

                <div className="col-12 col-lg-6 about_us_img mt-5">
                    <p></p>
                </div>

            </div>
        </>
    )
}