import logo from './logo.svg';
import './App.css';
import { AllRoutes } from './AllRoutes';

function App() {
  return (
    (
      <AllRoutes />
    )
  );
}

export default App;
