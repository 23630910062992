import { useNavigate } from "react-router-dom"

export const OurProducts = () => {
    const navigate = useNavigate()

    const Products = [
        {
            id: 0,
            Name: 'Namita',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0001.png",
            Name: 'SILWIN',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "All_Products"

        },
        {
            id: 1,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0010.png",
            Name: 'C.C.',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "All_Products"
        },
        {
            id: 2,
            Name: 'Dilip',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0002.png",
            Name: 'ROYAL A ONE',
            // bgcolor: 'pink',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 3,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0004.png",
            Name: 'ROYAL EMA',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 4,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0006.png",
            Name: 'ROYAL ABACTIN',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 5,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0007.png",
            Name: 'ROYAL SUPER STAR',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 6,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0008.png",
            Name: "ROYAL SUPER SETTER",
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 7,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0009.png",
            Name: 'ROYAL SUPER',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 8,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0011.png",
            Name: "ROYAL SUPER POWER",
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 9,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0012.png",
            Name: 'TARGET',
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },
        {
            id: 10,
            Name: 'Pawar',
            ImgSrc: "Static/Assets/Images/Products/IMG-20241023-WA0013.png",
            Name: "ROYAL AMI-STOR",
            // bgcolor: '#ececec',
            // border: '1px solid red',
            // RouteName: "https://www.royalchemical.in/"
        },

    ]

    return (
        <>
            <div className="row p-4 pt-5 p-lg-5 Products " id="Products" >

                <div className="col-12 mx-auto text-center pt-5">
                    <h6 className="green_color FW-BOLD">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="Chocolaty_color"> OUR </span> PRODUCTS </h3>
                </div>

                {
                    Products.map((Array) => {

                        return (

                            <div className="col-12 col-md-6 col-lg-3 mt-3 mt-lg-5 text-center"
                            // onClick={() => navigate(`${Array.RouteName}`)}
                            >
                                <div className=" " style={{ backgroundColor: (Array.bgcolor), border: (Array.border) }} >
                                    {/* <h5>{Array.Name}  </h5> */}
                                    <div className=" p-3 product_bg">
                                        <img src={Array.ImgSrc} alt="" srcset="" className="product_size" onClick={() => navigate('/')} />
                                    </div>
                                    <h6 className="fw-bold py-3 text_bg">{Array.Name}</h6>
                                </div>
                            </div>

                        )

                    }
                    )
                }
            </div>
        </>
    )
}