export const HeroSection = () => {
    return (
        <>
            <div className="row px-0" >
                <div className="col-12 px-0">
                    <div id="carouselExampleIndicators" class="carousel slide px-0" data-bs-ride="carousel">
                        <div class="carousel-indicators px-0">
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1" aria-label="Slide 2"></button>
                            <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2" aria-label="Slide 3"></button>
                        </div>
                        <div class="carousel-inner">
                            <div class="carousel-item ">
                                <img src="Static/Assets/Images/Banner/slider1-01.jpg" class="d-block w-100" alt="1" />
                            </div>
                            <div class="carousel-item active">
                                <img src="Static/Assets/Images/Banner/banner_28-02.jpg" class="d-block w-100" alt="2" />
                            </div>
                            <div class="carousel-item">
                                <img src="Static/Assets/Images/Banner/banner3-03.jpg" class="d-block w-100" alt="3" />
                            </div>
                        </div>
                        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Previous</span>
                        </button>
                        <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="visually-hidden">Next</span>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}