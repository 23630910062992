export const GetQuotes = () => {
    return (
        <>
            <div className="row p-3 pt-5 p-lg-5 GetQuotes ">

                <div className="col-12 col-lg-8 mx-auto ">
                    <h3 className="fw-bold green_color">
                        <span className="Chocolaty_color text-white" style={{textTransform:"uppercase"}}> Join us in shaping India's agricultural future.</span></h3>
                    <p className="pt-3 text-gray " style={{ textAlign: 'justify' }}>At Royal Fertilizers & Chemicals, we're dedicated to empowering Indian farmers with cutting-edge agricultural solutions. Since 2024, our mission has been to boost crop yields, promote sustainable farming practices, and contribute to India's agricultural growth.</p>
                </div>
                <div className="col-12 col-lg-3 mx-auto d-flex  align-items-center ">
                    <button class="button px-3 py-2 fw-bold"><span> GET A QUOTE NOW </span></button>
                </div>

            </div>
        </>
    )
}