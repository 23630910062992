import React from 'react';
import {
    BrowserRouter as Router,
    Route,
    Routes
} from 'react-router-dom';
import { MainHomePage } from "./Public/Components/IndexPage/MainHomePage"
import { All_Products } from './Public/Components/All_Products/All_Products';

export const AllRoutes = () => {
    return (
        <Router>
            <Routes>

                <Route exact path="/" element={<MainHomePage />} />
                {/* <Route exact path="/All_Products" element={<All_Products />} /> */}

            </Routes>
        </Router>
    )
}