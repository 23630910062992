

export const OurTeam = () => {
    return (
        <>
            <div className="row p-3 pt-lg-5 text-white mx-auto px-lg-5 GetQuotes pb-lg-4" id="OurTeam">

                <div className="col-12 px-lg-5 text-center">
                    <h6 className="green_color pt-4 ps-lg-5 pt-lg-5">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color ps-lg-5 py-1 pb-4"><span className="text-white">OUR  </span> TEAM </h3>
                </div>

                <div className="col-12 col-lg-6 py-3 ps-lg-5" id="team_hover">
                    <div className="row ps-lg-5">
                        <div className="col-12 col-md-4  d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Images/Team/Avinash Savagave.png" className="our_team" alt="" />
                        </div>
                        <div className="col-12 col-md-8 border_right">
                            <h4 className="fw-bold text-white pt-4"> Avinash Savagave </h4>
                            <h6 className="green_color">Managing Partner</h6>
                            <p className="gray_color" >MSc. Agri.</p>

                        </div>
                    </div>
                </div>

                <div className="col-12 col-lg-6 py-3" id="team_hover">
                    <div className="row ps-lg-5">
                        <div className="col-12 col-md-4  d-flex justify-content-center align-items-center">
                            <img src="Static/Assets/Images/Team/Vasim Pathan.png" className="our_team" alt="" />
                        </div>
                        <div className="col-12 col-md-8">
                            <h4 className="fw-bold text-white pt-4">Vasim Pathan </h4>
                            <h6 className="green_color">Advocate Co-Partner</h6>
                            <p className="gray_color" >CA.</p>
                          
                        </div>
                    </div>
                </div>


            </div>
        </>
    )
}