import { useEffect, useState } from "react";
import { ScrollTo } from "react-scroll-to";


export const Header = (props) => {
    const { active, setActive } = props


    useEffect(() => {
        const handleScroll = () => {
            const nav = document.querySelector('nav');
            const title = document.querySelector('nav div');

            if (window.scrollY >= 170) {
                nav.classList.add('fixed-header');
            } else {
                nav.classList.remove('fixed-header');
            }
        };

        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    },
        []);

    return (
        <>
            <div className="container-fluid" id="Home">
                <div className="row">
                    <div className="col-12">
                        <div className="navbar navbar-expand-lg bg-white px-lg-5">
                            <div className="col-lg-5 text-center pe-lg-5">
                                <a className="navbar-brand  text-center" href="#">
                                    <img src="Static/Assets/Images/rfc_logo-01.png" className="logo_bg" alt="" /></a>
                            </div>

                            <div className="col-10 col-lg-2 float-end d-none d-md-block">
                                <div className="row">
                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                        <img src="Static/Assets/Images/call2.png" alt="" />
                                    </div>
                                    <div className="col-9 border_left ps-3">
                                        <h6 className="fw-bold purple_color">Call us now</h6>
                                        <a href="tel:+8788141873" className="gray_color fs-7 ">+91  8788141873</a>
                                    </div>
                                </div>
                            </div>

                            <div className="col-2 float-start d-md-none pe-5">
                                <div className="row pe-5">
                                    <button className="navbar-toggler " type="button" data-bs-toggle="collapse"
                                        data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                                        aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon green_bg rounded"><i class="fa-solid fa-bars pt-1"></i></span>

                                    </button>
                                </div>
                            </div>

                            <div className="col-10 col-lg-3  float-end d-none d-md-block">
                                <div className="row">
                                    <div className="col-3 d-flex justify-content-center align-items-center">
                                        <img src="Static/Assets/Images/mail2.png" alt="" />
                                    </div>
                                    <div className="col-9 border_left ps-3">
                                        <h6 className="fw-bold purple_color"> Email at</h6>
                                        <a href="mailto:royalfertilizersandchemicals@gmail.com" className="fs-7 gray_color">
                                            royalfertilizersandchemicals@gmail.com</a>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-12 px-0 ">
                        <nav className="navbar py-0 navbar-expand-lg navbar-light  pe-lg-4 ">
                            <div className=" mx-auto">

                                <div className=" collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav mx-auto mb-2 mb-lg-0">

                                        <li className="nav-item">
                                            <a className={active == "Home" ? "nav-link actives" : "nav-link"} href="#Home" onClick={(() => { setActive("Home") })}>Home</a>
                                        </li>

                                        <li className="nav-item" >
                                            <a className={active == "AboutUs" ? "nav-link actives" : "nav-link"} href="#AboutUs" onClick={(() => { setActive("AboutUs") })}>
                                                About Us</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={active == "Products" ? "nav-link actives" : "nav-link"} href="#Products" onClick={(() => { setActive("Products") })}>
                                                Products </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={active == "ProductRange" ? "nav-link actives" : "nav-link"} href="#ProductRange" onClick={(() => { setActive("ProductRange") })}>
                                                Product Range</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={active == "OurTeam" ? "nav-link actives" : "nav-link"} href="#OurTeam" onClick={(() => { setActive("OurTeam") })}>
                                                Our Team</a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={active == "GALLERY" ? "nav-link actives" : "nav-link"} href="#GALLERY" onClick={(() => { setActive("GALLERY") })}>
                                                Gallery </a>
                                        </li>

                                        <li className="nav-item">
                                            <a className={active == "ContactUs" ? "nav-link actives" : "nav-link"} href="#ContactUs" onClick={(() => { setActive("ContactUs") })}>
                                                Contact Us</a>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    )
}