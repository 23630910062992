import { useState } from "react"
import { Footer } from "../Footer/Footer"
import { Header } from "../Header.js/Header"
import { AboutUs } from "./AboutUs"
import { ContactUs } from "./ContactUs"
import { Gallery } from "./Gallery"
import { GetQuotes } from "./GetQuotes"
import { HeroSection } from "./HeroSection"
import { OurProducts } from "./OurProducts"
import { OurStory } from "./OurStory"
import { OurTeam } from "./OurTeam"
import { ProductRange } from "./ProductRange"
import { SustainabilityPledge } from "./SustainabilityPledge"
import { WeCollaborate } from "./WeCollaborate"
import { Whatsapp } from "./Whatsapp"
import { WhyChooseUs } from "./WhyChooseUs"


export const MainHomePage = () => {
    const [active, setActive] = useState("Home")

    return (
        <>
            <Header
                active={active}
                setActive={setActive} />
            <HeroSection />
            <AboutUs />
            <WhyChooseUs />
            <OurProducts />
            <WeCollaborate />
            <ProductRange />
            <OurTeam />
            <Gallery />
            <SustainabilityPledge />
            <OurStory />
            <GetQuotes />
            {/* <Whatsapp /> */}
            <ContactUs />
            <Footer
                active={active}
                setActive={setActive} />

        </>
    )
}