

export const SustainabilityPledge = () => {
    return (
        <>
            <div className="row p-3 text-white mx-auto px-lg-5" style={{backgroundColor:'#72a33f'}}>


                <div className="col-12 col-lg-6 px-lg-5">
                    <h6 className="text-white FW-BOLD pt-4 ps-lg-5 pt-lg-5">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold text-white ps-lg-5 py-1"><span className="text-dark"> SUSTAINABILITY    </span> PLEDGE </h3>
                    <h6 className="py-3 ps-lg-5">We prioritize environmental stewardship and social responsibility:</h6>

                    <p className="ps-lg-5"><i class="fa-brands fa-envira pe-2" style={{color:'#fff'}}></i> Eco-friendly packaging and distribution</p>
                    <p className="ps-lg-5"><i class="fa-brands fa-envira pe-2" style={{color:'#fff'}}></i> Integrated Pest Management practices</p>
                    <p className="ps-lg-5"><i class="fa-brands fa-envira pe-2" style={{color:'#fff'}}></i> Farmer education and community development initiatives</p>
                    <p className="ps-lg-5"><i class="fa-brands fa-envira pe-2" style={{color:'#fff'}}></i> Strategic Partnerships</p>
                </div>

                <div className="col-12 col-lg-6 d-flex justify-content-center align-items-center">
                    <img src="Static/Assets/Images/Sustainability Pledge-01.png" alt="" className="Sustainability"  />
                </div>
            </div>
        </>
    )
}