import { useState } from "react";

export const ContactUs = () => {

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        contactNumber: '',
        // subject: '',
        message: '',
    });

    const [status, setStatus] = useState(null);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const response = await fetch('https://formspree.io/f/xldegeoo', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (response.ok) {
            setStatus({ success: true, message: 'Message sent Succefully!' });
            setFormData({
                name: '',
                email: '',
                contactNumber: '',
                // subject: '',
                message: '',
            }); // Reset form
        } else {
            setStatus({ success: false, message: 'Error sending message.' });
        }
    };

    return (
        <>
            <div className="row p-3 pt-5  p-lg-5 Contact_us" id="ContactUs">

                <div className="col-12 col-lg-6 pt-lg-5 mx-auto text-center px-lg-5">

                    <h6 className="green_color FW-BOLD">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="Chocolaty_color"> REQUEST FOR </span> A QUOTE </h3>

                    <div className="ps-lg-5 pt-4">

                        <form onSubmit={handleSubmit}>

                            <div class="mb-3">
                                <input
                                    type="text"
                                    className="form-control"
                                    id="name"
                                    name="name"
                                    placeholder="Full Name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div class="mb-3">
                                <input
                                    type="tel"
                                    className="form-control form_controls"
                                    id="contactNumber"
                                    name="contactNumber"
                                    placeholder="Mobile Number"
                                    value={formData.contactNumber}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div class="mb-3">

                                <input
                                    type="email"
                                    className="form-control form_controls"
                                    id="email"
                                    name="email"
                                    placeholder="Email Id"
                                    value={formData.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>


                            <div class="mb-3">
                                <textarea
                                    className="form-control form_controls"
                                    id="message"
                                    name="message" rows="3"
                                    placeholder="Input Message Here..."
                                    value={formData.message}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <p className="float-end">
                                <button type="submit" class="button get_quotes fw-bold "><i class="fa-solid fa-arrow-up-right-from-square pe-1"></i>  Get a Quote Now </button>
                            </p>

                        </form>
                        {status && <p className={status.success ? 'green_color fw-bold' : 'error'}>{status.message}</p>}

                    </div>

                </div>

                <div className="col-12 col-lg-6 px-lg-5 px-4  pt-lg-5">

                    <h6 className="green_color FW-BOLD">Royal Fertilizers & Chemicals</h6>

                    <h3 className="fw-bold green_color">
                        <span className="Chocolaty_color"> LET US </span> HELP
                        <span className="Chocolaty_color"> YOU! </span>
                    </h3>

                    <p className="fs-5 green_color pt-4">Manufacturing and Exporting a remarkable</p>
                    <p className=" Chocolaty_color pe-lg-5" style={{ textAlign: "justify" }}>
                        Customer Satisfaction is a measure of product quality. We use the latest technology and the best resources to make top quality</p>

                    <p className="pt-3">Facing any problem to get a quote!</p>

                    <div className="row">

                        {/* --------------------------Call-------------------------- */}

                        <div className="col-1 d-flex align-items-center">
                            <span className="green_color">
                                <i class="fa-solid fa-phone-volume fs-5 pe-3"></i>
                            </span>
                        </div>
                        <div className="col-11">
                            <a href="tel:+8788141873" className="purple_color fw-bold">+91  8788141873</a>
                        </div>

                        {/* --------------------------Email-------------------------- */}

                        <div className="col-1 d-flex align-items-center pt-4">
                            <span className="green_color">
                                <span className="green_color"><i class="fa-solid fs-5 pe-3 fa-envelope"></i></span>
                            </span>
                        </div>
                        <div className="col-11 pt-4">
                            <a href="mailto:royalfertilizersandchemicals@gmail.com" className="purple_color fw-bold ">
                                royalfertilizersandchemicals@gmail.com</a>
                        </div>


                    </div>

                </div>

            </div>
        </>
    )
}