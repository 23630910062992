

export const Gallery = () => {
    return (
        <>
            <div className="row p-3 pt-lg-5 mx-auto px-lg-5  pb-lg-5" id="GALLERY">

                <div className="col-12 px-lg-5 text-center">
                    <h6 className="green_color pt-4 ps-lg-5 pt-lg-5">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold ps-lg-5 py-1 text-dark"> GALLERY </h3>
                </div>

                <div className="col-12 col-lg-4 p-4">
                    <div class="media">
                        <div class="layer">
                            <p>Royal Fertilizers & Chemicals</p>
                        </div>
                        <img
                            src="https://img.freepik.com/free-photo/young-lady-covered-by-red-jacket-with-green-field_181624-29480.jpg?t=st=1729862839~exp=1729866439~hmac=41656c1d128fb129d7ada56cc44cd1b236adbf1a562d84260b0d14dca317245c&w=740"
                            alt="" />
                    </div>
                </div>

                <div className="col-12 col-lg-4 p-4">
                    <div class="media">
                        <div class="layer">
                            <p>Royal Fertilizers & Chemicals</p>
                        </div>
                        <img
                            src="https://img.freepik.com/free-photo/green-leaves-plants-grow-nature-freshness-outdoors-generated-by-artificial-intelligence_25030-60476.jpg?t=st=1729842991~exp=1729846591~hmac=3b8e214f223c9c367349cecc48aba2c67a3689fc78fd8198ad7910e9f9aec5e1&w=826"
                            alt="" />
                    </div>
                </div>

                <div className="col-12 col-lg-4 p-4">
                    <div class="media">
                        <div class="layer">
                            <p>Royal Fertilizers & Chemicals</p>
                        </div>
                        <img
                            src="https://i.ytimg.com/vi/fNzq5YasHX8/hq720.jpg?sqp=-oaymwEhCK4FEIIDSFryq4qpAxMIARUAAAAAGAElAADIQj0AgKJD&rs=AOn4CLDyiZuI08iRu4M74GKNmWI6MwDnZw"
                            alt="" />
                    </div>
                </div>

            </div>
        </>
    )
}