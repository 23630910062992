import { useState } from "react"

export const Footer = (props) => {

    const { active, setActive } = props


    return (
        <>
            <div className="row bg_footer px-lg-5 text-gray pb-3">
                <div className="col-12 col-lg-5 px-5 py-5">
                    <h5 className="fw-bold pb-3 text-white">About</h5>
                    <p className="pe-lg-5 me-lg-5" style={{ textAlign: 'justify' }}>
                    At Royal Fertilizers & Chemicals, we're dedicated to empowering Indian farmers with cutting-edge agricultural solutions. Since 2024, our mission has been to boost crop yields, promote sustainable farming practices, and contribute to India's agricultural growth.
                    </p>
                </div>

                <div className="col-12 col-lg-3 px-5 py-1 py-lg-5 Usefull_Links">

                    <h5 className="fw-bold pb-3 text-white">Usefull Links</h5>

                    <p className="mb-2"><i class="fa-solid fa-chevron-left pe-3"></i>
                        {/* <a href="#AboutUs" className="text-gray" onClick={(() => { setActive("AboutUs") })}>
                            About Us </a> */}
                        <a className={active == "AboutUs" ? "text-gray" : "text-gray"} href="#AboutUs" onClick={(() => { setActive("AboutUs") })}>
                            About Us</a>
                    </p>

                    <p className="mb-2">
                        <i class="fa-solid fa-chevron-left pe-3"></i>
                        {/* <a href="#Products" className="text-gray"> Products </a> */}
                        <a className={active == "Products" ? "text-gray" : "text-gray"} href="#Products" onClick={(() => { setActive("Products") })}>
                            Products </a>
                    </p>

                    <p className="mb-2"><i class="fa-solid fa-chevron-left pe-3"></i>
                        {/* <a className="text-gray" href="#ProductRange"> Product Range </a> */}
                        <a className={active == "ProductRange" ? "text-gray" : "text-gray"} href="#ProductRange" onClick={(() => { setActive("ProductRange") })}>
                            Product Range</a>
                    </p>

                    <p className="mb-2"><i class="fa-solid fa-chevron-left pe-3"></i>
                        {/* <a className="text-gray" href="#OurTeam"> Our Team </a> */}
                        <a className={active == "OurTeam" ? "text-gray" : "text-gray"} href="#OurTeam" onClick={(() => { setActive("OurTeam") })}>
                            Our Team</a>
                    </p>

                    <p className="mb-2"><i class="fa-solid fa-chevron-left pe-3"></i>
                        {/* <a className="text-gray" href="#GALLERY"> Gallery </a> */}
                        <a className={active == "GALLERY" ? "text-gray" : "text-gray"} href="#GALLERY" onClick={(() => { setActive("GALLERY") })}>
                            Gallery </a>
                    </p>

                    <p className="mb-2"><i class="fa-solid fa-chevron-left pe-3"></i>
                        {/* <a className="text-gray" href="#ContactUs"> Contact Us </a> */}
                        <a className={active == "ContactUs" ? "text-gray" : "text-gray"} href="#ContactUs" onClick={(() => { setActive("ContactUs") })}>
                            Contact Us</a>
                    </p>

                </div>

                <div className="col-12 col-lg-4 px-5 py-5">
                    <h5 className="fw-bold pb-3 text-white">Address</h5>

                    <h6 className="green_color">Factory Address :</h6>
                    <p style={{ textAlign: 'justify' }}>
                        Ground floor, D-38/1, Near Reliance Industries, Kurkumbh MIDC, Taluka - Daund, Dist - Pune - 413802
                    </p>

                    <h6 className="green_color">Office Address :</h6>
                    <p style={{ textAlign: 'justify' }}>
                        Office No - 311, 3rd floor, Amanora Chamber, Amanora Mall, Hadapsar, Pune  - 411028
                    </p>

                </div>
            </div>



            <div className="row footer3" style={{ background: '#1d1d1d' }}>

                <div className="col-12 col-lg-4 footer2 py-4 py-lg-4 px-4 text-gray">

                    <span className="green_color"><i class="fa-solid fa-phone-volume"></i></span>
                    <span className="fw-bold px-3 text-white">  Phone:  </span>
                    <a href="tel:+8788141873" className=" text-gray">+91  8788141873</a>

                </div>

                <div className="col-12 col-lg-5 footer2 py-4 py-lg-4 px-4 text-gray ">

                    <span className="green_color"><i class="fa-solid fa-envelope"></i></span>
                    <span className="fw-bold px-3 text-white">  Email: </span>
                    <a href="mailto:royalfertilizersandchemicals@gmail.com" className="text-gray ">royalfertilizersandchemicals@gmail.com</a>


                </div>

                <div className="col-12 col-lg-3 footer2 py-4 py-lg-4 px-4 text-gray">
                    {/* 
                    <span className="green_color"><i class="fa-solid fa-location-arrow"></i></span>
                    <span className="fw-bold px-3 text-white">  Address:  </span> 121 Park Drive, Varick Str, NY */}
                    <p className="d-flex float-end mb-0">
                        <span><img src="Static/Assets/Images/facebook.png" alt="" className="icons" /></span>
                        <span><img src="Static/Assets/Images/instagram.png" alt=""  className="icons"/></span>
                        <span><img src="Static/Assets/Images/twitter.png" alt=""  className="icons"/></span>
                        <span><img src="Static/Assets/Images/whatsapp.png" alt="" className="icons" /></span>
                        <span><img src="Static/Assets/Images/linkedin.png" alt="" className="icons" /></span>
                        {/* <i class="fa-brands fa-facebook-f icons"></i>
                        <i class="fa-brands fa-instagram icons"></i>
                        <i class="fa-brands fa-twitter icons"></i>
                        <i class="fa-brands fa-whatsapp icons"></i>
                        <i class="fa-brands fa-linkedin-in icons"></i> */}
                        {/* <span className="green_bg px-3 pt-2 rounded ms-4">
                            <i class="fa-solid fa-chevron-up"></i>
                        </span> */}
                    </p>
                </div>


            </div>



            <div className="row px-4 px-lg-5" style={{ background: '#1d1d1d' }}>
                <div className="col-12 col-lg-6  text-gray px-2  px-lg-5 py-lg-4">

                    <p className="pt-4 pt-lg-2 mb-0">Copyrights © 2024 All Rights Reserved. Powered by CDAT Pune.</p>

                </div>

                {/* <div className="col-12 col-lg-6 py-4 px-lg-5 ps-5 pb-5 pb-lg-0">

                </div> */}
            </div>
        </>
    )
}