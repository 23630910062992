export const WhyChooseUs = () => {
    return (
        <>
            <div className="row mx-auto p-3 pt-5 p-lg-5 GetQuotes">

                <div className="col-12 mx-auto text-center">
                    <h6 className="green_color FW-BOLD">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="text-white"> WHY YOU </span> CHOOSE US? </h3>
                </div>

                <div className="col-12 pt-5">
                    <section class="skills">
                            <div class="skills-flex-container">
                                <div class="box">
                                    <div class="image">
                                        <img src="Static/Assets/Images/1-01.png" alt="Cost-Effective Products" />
                                    </div>
                                    <div class="title">Cost-Effective Products</div>
                                </div>
                                <div class="box">
                                    <div class="image">
                                        <img src="Static/Assets/Images/1-02.png" alt="Customized Packaging Options Available" />
                                    </div>
                                    <div class="title">Customized Packaging Options Available</div>
                                </div>
                                <div class="box">
                                    <div class="image">
                                        <img src="Static/Assets/Images/1-04.png" alt="Experienced Team Of Professionals" />
                                    </div>
                                    <div class="title">Experienced Team Of Professionals</div>
                                </div>
                                <div class="box">
                                    <div class="image">
                                        <img src="Static/Assets/Images/1-03.png" alt="Prompt Delivery Due To Swift Logistics" />
                                    </div>
                                    <div class="title">Prompt Delivery Due To Swift Logistics</div>
                                </div>
                            </div>
                    </section>
                </div>

            </div>
        </>
    )
}