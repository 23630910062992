export const WeCollaborate = () => {
    return (
        <>
            <div className="row mx-auto p-3 pt-5 p-lg-5 GetQuotes">

                <div className="col-12 mx-auto text-center">
                    <h6 className="green_color FW-BOLD">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="text-white">WE COLLABORATE  </span> WITH </h3>
                </div>

                <div className="col-12 pt-5">
                    <section class="skills">
                            <div class="skills-flex-container">
                                <div class="box col-12 col-lg-4">
                                    <div class="image">
                                        <img src="Static/Assets/Images/innovative_solutions.png" alt="Cost-Effective Products" />
                                    </div>
                                    <div class="title">Research institutions for innovative solutions</div>
                                </div>
                                <div class="box col-12 col-lg-4">
                                    <div class="image">
                                        <img src="Static/Assets/Images/agriculture_knowedge.png" alt="Customized Packaging Options Available" />
                                    </div>
                                    <div class="title">Agricultural universities for knowledge sharing</div>
                                </div>
                                <div class="box col-12 col-lg-4">
                                    <div class="image">
                                        <img src="Static/Assets/Images/advocacy-01.png" alt="Experienced Team Of Professionals" />
                                    </div>
                                    <div class="title">Industry associations for policy advocacy</div>
                                </div>
                               
                            </div>
                    </section>
                </div>

            </div>
        </>
    )
}