

export const ProductRange = () => {
    return (
        <>
            <div className="row p-3 pb-lg-0 px-lg-5 col-lg-11 mx-auto" id="ProductRange">

                <div className="col-12 text-center  px-5 pt-3" >

                    <h6 className="green_color FW-BOLD pt-5">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color"><span className="Chocolaty_color"> PRODUCT  </span> RANGE </h3>
                    <h6>Our comprehensive portfolio includes:</h6>

                </div>

                <div className="col-12 col-lg-6">
                    <span className="fw-bold fs-5 Chocolaty_color"  style={{textTransform:'uppercase'}}>Pesticides</span>
                    <img src="Static/Assets/Images/product range-01.png" alt="" style={{ width: '100%' }} />
                </div>

                <div className="col-12 col-lg-6">
                <span className="fw-bold fs-5 Chocolaty_color" style={{display:"block", float:"right", textTransform:'uppercase'}}>Fertilizers</span>
                  <img src="Static/Assets/Images/product range2-01.png" alt="" style={{ width: '100%' }} />
                    
                </div>
            </div>
        </>
    )
}