export const OurStory = () => {
    return (
        <>
            <div className="row p-3  px-lg-5 pb-lg-5 my-lg-2 col-lg-11 mx-auto ">

                <div className="col-12 col-lg-6 our_Product_compo mt-5">
                    <p></p>
                </div>

                <div className="col-12 col-lg-6 pt-3 mt-lg-3 pt-lg-5 ps-lg-5 pb-lg-4 bg_about2">
                    <h6 className="green_color FW-BOLD  ps-lg-5">Royal Fertilizers & Chemicals</h6>
                    <h3 className="fw-bold green_color pb-3 ps-lg-5"><span className="Chocolaty_color"> OUR   </span> STORY </h3>
                    <p className=" ps-lg-5">Backed by our parent company, Royal Pesticides India Pvt. Ltd., we leverage expertise and innovation to deliver top-notch Fertilizerss and pesticides. </p>

                    <p className=" ps-lg-5">Our products are carefully sourced from trusted partners, ensuring unparalleled quality and efficacy.</p>
                </div>



            </div>
        </>
    )
}